<template>
    <div :class="[$style['wrap']]">
        <slot />
    </div>
</template>

<style lang="postcss" module>
.wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--wrap);
    padding-left: 20px;
    padding-right: 20px;
}
</style>
