import type { Navigation } from '~/models/Navigation';
import type { ExtranetNavigationItem } from '~/types/Navigation';

const primaryNavigationItems: ExtranetNavigationItem[] = [
    {
        icon: 'ph-medal',
        key: 'recertification',
        label: 'Hercertificering',
        shouldFilter: true,
        to: {
            path: '/recertification',
        },
    },
    {
        icon: 'ph-file-text',
        key: 'certificates',
        label: 'Certificaten',
        shouldFilter: true,
        to: {
            path: '/certificates',
        },
    },
    {
        icon: 'ph-medal',
        key: 'furtherTraining',
        label: 'Nascholing',
        shouldFilter: true,
        to: {
            path: '/further-training',
        },
    },
    {
        icon: 'ph-file-text',
        key: 'registrations',
        label: 'Registraties',
        shouldFilter: true,
        to: {
            path: '/registrations',
        },
    },
    {
        icon: 'ph-file-search',
        key: 'courses',
        label: 'Gevolgde cursussen',
        to: {
            path: '/courses',
        },
    },
    {
        icon: 'ph-folders',
        key: 'documents',
        label: 'Documenten',
        to: {
            path: '/documents',
        },
    },
    {
        icon: 'ph-notepad',
        key: 'myInvoices',
        label: 'Mijn facturen',
        to: {
            path: '/my-invoices',
        },
    },
];

export const getPrimaryNavigationItems =
    (filters?: Navigation): ExtranetNavigationItem[] => {
        if (!filters) {
            return primaryNavigationItems;
        }

        return primaryNavigationItems.filter(
            item => filters[item.key as keyof Navigation] || !item.shouldFilter,
        );
    };

export const secondaryNavigationItems: ExtranetNavigationItem[] = [
    {
        icon: 'ph-user',
        key: 'profile',
        label: 'Mijn account',
        to: {
            path: '/profile',
        },
    },
    {
        href: 'https://www.vastgoedcert.nl',
        icon: 'ph-arrow-square-out',
        key: 'externalUrl',
        label: 'Ga naar de website',
    },
    {
        icon: 'ph-sign-out',
        isLogoutButton: true,
        key: 'logout',
        label: 'Uitloggen',
    },
];

export const getSecondaryNavigationItems = (
    kNumber: string | null,
    showCoursePage: boolean,
): ExtranetNavigationItem[] => {
    const coursePageItem: ExtranetNavigationItem | null = showCoursePage ? {
        icon: 'ph-graduation-cap',
        key: 'signup',
        label: kNumber ? 'Register-Makelaar' : 'Kandidaat-Makelaar',
        to: {
            path: '/opleiding',
        },
    } : null;

    return [
        secondaryNavigationItems[0],
        ...(coursePageItem ? [coursePageItem] : []),
        secondaryNavigationItems[1],
        secondaryNavigationItems[2],
    ];
};

export const nonLoggedInNavigationItems: ExtranetNavigationItem[] = [
    {
        icon: 'ph-sign-in',
        key: 'login',
        label: 'Inloggen',
        to: {
            path: '/login',
        },
    },
    {
        icon: 'ph-password',
        key: 'forgotPassword',
        label: 'Wachtwoord vergeten',
        to: {
            path: '/login/forgot-password',
        },
    },
];
